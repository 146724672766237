exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-client-dok-32-js": () => import("./../../../src/pages/client/DOK32.js" /* webpackChunkName: "component---src-pages-client-dok-32-js" */),
  "component---src-pages-client-it-flakes-js": () => import("./../../../src/pages/client/it-flakes.js" /* webpackChunkName: "component---src-pages-client-it-flakes-js" */),
  "component---src-pages-client-smart-board-js": () => import("./../../../src/pages/client/smart-board.js" /* webpackChunkName: "component---src-pages-client-smart-board-js" */),
  "component---src-pages-client-stories-js": () => import("./../../../src/pages/client-stories.js" /* webpackChunkName: "component---src-pages-client-stories-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookies-policy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-end-to-end-js": () => import("./../../../src/pages/end-to-end.js" /* webpackChunkName: "component---src-pages-end-to-end-js" */),
  "component---src-pages-hall-of-fame-js": () => import("./../../../src/pages/hall-of-fame.js" /* webpackChunkName: "component---src-pages-hall-of-fame-js" */),
  "component---src-pages-how-we-work-js": () => import("./../../../src/pages/how-we-work.js" /* webpackChunkName: "component---src-pages-how-we-work-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-team-augmentation-js": () => import("./../../../src/pages/team-augmentation.js" /* webpackChunkName: "component---src-pages-team-augmentation-js" */),
  "component---src-pages-technical-experties-js": () => import("./../../../src/pages/technical-experties.js" /* webpackChunkName: "component---src-pages-technical-experties-js" */),
  "component---src-pages-terms-services-js": () => import("./../../../src/pages/terms-services.js" /* webpackChunkName: "component---src-pages-terms-services-js" */),
  "component---src-pages-why-choose-us-js": () => import("./../../../src/pages/why-choose-us.js" /* webpackChunkName: "component---src-pages-why-choose-us-js" */),
  "component---src-pages-work-with-us-js": () => import("./../../../src/pages/work-with-us.js" /* webpackChunkName: "component---src-pages-work-with-us-js" */)
}

